<template>
  <div
    class="app-header tw-bg-dark tw-h-16 tw-w-full tw-flex tw-items-center tw-px-6 tw-justify-between"
  >
    <div class="tw-flex tw-h-full tw-items-center tw-w-full">
      <nuxt-link to="/" class="tw-mr-4">
        <img alt="logo" src="~/assets/images/logo.svg" />
      </nuxt-link>
      <div
        class="tw-flex-grow tw-flex tw-justify-between tw-items-center tw-h-full"
      >
        <a-menu mode="horizontal">
          <a-menu-item key="products">
            <nuxt-link
              v-if="useHasPermission('form_editor')"
              class="hover:tw-bg-not-so-dark tw-text-white hover:tw-text-white tw-h-full tw-flex tw-items-center tw-px-6 tw-mr-0"
              to="/products"
              active-class="tw-bg-not-so-dark tw-text-underline"
            >
              <AppstoreOutlined class="tw-mr-2" /> Products
            </nuxt-link>
          </a-menu-item>

          <a-sub-menu
            v-if="useHasPermission('stats')"
            key="stats"
            title="Stats"
            class="hover:tw-bg-not-so-dark tw-text-white hover:tw-text-white tw-h-full tw-flex tw-items-center tw-px-6"
          >
            <template #icon>
              <LineChartOutlined />
            </template>
            <a-menu-item key="Stats1">
              <nuxt-link to="/stats">Stats</nuxt-link>
            </a-menu-item>
            <a-menu-item key="Stats2">
              <nuxt-link to="/experiments">Experiments</nuxt-link>
            </a-menu-item>
          </a-sub-menu>

          <a-sub-menu
            v-if="useHasPermission('support')"
            key="orders"
            title="Orders"
          >
            <template #icon>
              <ProfileOutlined />
            </template>
            <a-menu-item key="orders:1">
              <nuxt-link to="/orders">New orders</nuxt-link>
            </a-menu-item>
            <a-menu-item key="orders:2">
              <nuxt-link to="/orders/compliance">Compliance Orders</nuxt-link>
            </a-menu-item>
            <a-menu-item key="orders:3">
              <nuxt-link to="/orders/compliancefilings"
                >Compliance Filings</nuxt-link
              >
            </a-menu-item>
          </a-sub-menu>

          <a-sub-menu
            v-if="useHasPermission('form_editor')"
            key="taskssubmenu"
            title="Tasks"
          >
            <template #icon>
              <SettingOutlined />
            </template>
            <a-menu-item key="tasks:1">
              <nuxt-link to="/tasks">Current Tasks</nuxt-link>
            </a-menu-item>
            <a-menu-item key="tasks:2">
              <nuxt-link to="/admin/task_recipes">Task Recipes</nuxt-link>
            </a-menu-item>
          </a-sub-menu>

          <a-menu-item key="tasks" v-else>
            <nuxt-link
              class="hover:tw-bg-not-so-dark tw-text-white hover:tw-text-white tw-h-full tw-flex tw-items-center tw-px-6"
              to="/tasks"
              active-class="tw-bg-not-so-dark tw-text-underline"
            >
              <AppstoreOutlined class="tw-mr-2" /> Tasks
            </nuxt-link>
          </a-menu-item>

          <a-sub-menu
            v-if="useHasPermission('superuser')"
            key="admin"
            title="Admin"
          >
            <template #icon>
              <SettingOutlined />
            </template>
            <a-menu-item key="admin:1">
              <nuxt-link to="/admin/permissions">Permissions</nuxt-link>
            </a-menu-item>
            <a-menu-item key="admin:2">
              <nuxt-link to="/admin/teams">Teams</nuxt-link>
            </a-menu-item>
            <a-menu-item key="admin:3">
              <nuxt-link to="/chargebacks">Chargebacks</nuxt-link>
            </a-menu-item>
            <a-menu-item key="admin:4">
              <nuxt-link to="/admin/stripe_alerts">Stripe Alerts</nuxt-link>
            </a-menu-item>
            <a-menu-item key="admin:5">
              <nuxt-link to="/admin/plans">User Plans</nuxt-link>
            </a-menu-item>
            <a-menu-item key="admin:6">
              <nuxt-link to="/admin/state_info">State Info</nuxt-link>
            </a-menu-item>
            <a-menu-item key="admin:7">
              <nuxt-link to="/admin/cdrn_search">CDRN Search</nuxt-link>
            </a-menu-item>
            <a-menu-item key="admin:8">
              <nuxt-link to="/admin/butter_pages">Butter Pages</nuxt-link>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>

        <div class="tw-flex tw-items-center">
          <app-search />
          <a-dropdown
            class="tw-block tw-pl-6 notifications-icon"
            :trigger="['click']"
            arrow
            overlayClassName="notifications-dropdown"
          >
            <a-badge :count="unread.length" class="tw-cursor-pointer">
              <BellFilled :style="{ fontSize: '20px', color: '#fff' }" />
            </a-badge>
            <template #overlay #fallback>
              <div
                class="tw-bg-white tw-pb-4 tw-rounded-md tw-shadow-md tw-overflow-hidden"
              >
                <NotificationItem
                  v-for="notification in notifications"
                  :notification="notification"
                />

                <nuxt-link
                  to="/notifications"
                  class="tw-ml-auto tw-text-abbey hover:tw-text-azure tw-w-fit tw-mr-4 tw-block tw-pt-4"
                  >View All</nuxt-link
                >
              </div>
            </template>
          </a-dropdown>

          <a-dropdown class="tw-pl-4 tw-ml-4 tw-block" :trigger="['click']">
            <UserOutlined :style="{ fontSize: '20px', color: '#fff' }" />
            <template #overlay #fallback>
              <a-menu @click="signOut({ callbackUrl: '/login' })">
                <a-menu-item key="logout"> Log Out </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  UserOutlined,
  AppstoreOutlined,
  ProfileOutlined,
  LineChartOutlined,
  SettingOutlined,
  BellFilled,
} from '@ant-design/icons-vue'
import AppSearch from '../AppSearch/AppSearch.vue'
import NotificationItem from '../../shared/NotificationItem.vue'
import Notification from '~/models/Notification'
import { useStore } from 'vuex'

const store = useStore()

let signOut: any = null
onMounted(() => {
  const sess = useSession()
  signOut = sess.signOut
})

const notifications = computed(() => {
  return store
    .$db()
    .model(Notification)
    .query()
    .orderBy('created_at', 'desc')
    .limit(8)
    .get()
})

const keepPolling = ref(true)
const unread = computed(() => {
  return store.$db().model(Notification).query().where('read_at', 'null').get()
})
const areNotificationsLoaded = ref(false)
const fetchNotifications = async () => {
  const notificationsData = await useFetchAuth(`notifications`, {
    params: {
      sort_by: '-created_at',
    },
  })
  store
    .$db()
    .model(Notification)
    .insertOrUpdate({ data: notificationsData.data })

  areNotificationsLoaded.value = true
  store
    .$db()
    .model(Notification)
    .commit((state) => {
      state.total = notificationsData.meta.total
    })

  if (keepPolling.value) {
    setTimeout(fetchNotifications, 120000)
  }
}
fetchNotifications()

onUnmounted(() => {
  keepPolling.value = false
})
</script>

<style lang="scss">
.notifications-dropdown {
  min-width: 350px !important;
  top: 40px !important;
  margin-left: 30px !important;
}
.app-header {
  .ant-badge-count {
    height: 16px;
    min-width: 16px;
    font-size: 10px;
    line-height: 16px;
    box-shadow: 0 0 0 1px #353b4b;
  }
  .notifications-icon {
    height: 50px;
    display: flex !important;
    align-items: center;
    width: 50px;
    justify-content: center;
    sup {
      top: 16px;
    }
  }
  .ant-menu,
  .ant-menu-submenu {
    background-color: transparent;
    height: 100%;
    border-bottom: 0;

    li {
      &.ant-menu-item-only-child {
        padding: 0 !important;
      }
      border-bottom: 0 !important;
      padding: 0;
      height: 100%;
      margin-top: 0;
      top: 0;

      &:hover {
        border-bottom: 0;
      }
      &::after {
        border-bottom: 0 !important;
      }
    }
    .ant-menu-submenu-title {
      padding: 0;
      height: 100%;
      color: white;
      align-items: center;
      display: flex;
    }
  }
}
</style>
